import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/router/auth'

Vue.use(Router)

function checkSession(next, redirectPath = '/login') {
  if (!Vue.prototype.$session.exists()) {
    return next(redirectPath)
  }
  auth.check().then((res) => {
    if (res) {
      next()
    } else {
      next(redirectPath)
    }
  })
}

const routes = [
  {
    path: '*',
    component: () => import('@/pages/errors/404.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
    beforeEnter(to, from, next) {
      if (Vue.prototype.$session.exists()) {
        auth.check().then((res) => {
          next(res ? '/home' : undefined)
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/reset/password/:id',
    name: 'Reset',
    component: () => import('@/pages/Reset.vue')
  },
  {
    path: '/aviso-de-privacidad',
    name: 'NoticePrivacy',
    component: () => import('@/pages/NoticePrivacy.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terms',
    component: () => import('@/pages/Terms.vue')
  },
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Home' },
    component: () => import('@/pages/Root.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/pages/Home.vue')
      },
      {
        path: '/perfil',
        name: 'Profile',
        component: () => import('@/pages/Profile.vue')
      },
      {
        path: '/semaforo',
        name: 'Semaphore',
        component: () => import('@/pages/Semaphore.vue')
      },
      {
        path: '/operadores',
        name: 'Driver',
        component: () => import('@/pages/Driver.vue')
      },
      {
        path: '/mapa',
        name: 'Map',
        component: () => import('@/pages/Map.vue')
      },
      {
        path: '/pruebas-de-simulacion',
        name: 'SimulationTest',
        component: () => import('@/pages/SimulationTest.vue')
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicRoutes = ['/login', '/reset/password/:id', '/aviso-de-privacidad', '/terminos-y-condiciones']
  if (!publicRoutes.includes(to.path)) {
    checkSession(next)
  } else {
    next()
  }
})

export default router

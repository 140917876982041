import Vue from 'vue'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { axiosInstance } from '../utils'

const host = process.env.VUE_APP_API_SERVER
const path = '/api/check'

export default {
  check() {
    let jwt = Vue.prototype.$session.get('jwt')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
    return axios
      .get(host + path)
      .then((response) => {
        let data = jwt_decode(jwt)
        Vue.prototype.$session.set('user', data.user.username)
        Vue.prototype.$session.set('rol', response.data.roles)
        Vue.prototype.$session.set('companies', response.data.empresas)
        return true
      })
      .catch((error) => {
        Vue.prototype.$session.destroy()
        return false
      })
  }
}
